import React from 'react';
import Fade from 'react-reveal/Fade';
import styles from "./workSection.module.scss";

const WorkSection = ({ children, id }) => (
  <Fade>
    <div id={id} className={styles.container}>
      <div className={styles.header}>
        <p className={styles.subtitle}>Our work</p>
        <h2>We help our clients to build amazing products</h2>
        <p>We are a team of skilled professionals that have built multiple web and mobile projects.
        We've helped to grow startups based in UK, USA, Dubai and also created our own products.</p>
      </div>
      {children}
    </div>
  </Fade>
)

export default WorkSection
