import React from 'react';
import cx from 'classnames';
import Img from "gatsby-image";
import Fade from 'react-reveal/Fade';
import styles from "./imageSection.module.scss";

const ImageSection = ({ children, image, rowReverse = false, subtitle, title, content, id }) => (
  <Fade>
    <div id={id} className={rowReverse ? styles.reversedContainer : styles.container}>
      <div className={cx(styles.col, styles.col1)}>
        <div className={styles.imageWrapper}>
          <Img className={styles.image} fluid={image} alt="Cybertent services" />
        </div>
      </div>
      <div className={cx(styles.col, styles.col2)}>
        <p className={styles.subtitle}>{subtitle}</p>
        <h2>{title}</h2>
        <p>{content}</p>
        {children}
      </div>
    </div>
  </Fade>
)

export default ImageSection
