import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ImageSection from '../components/ImageSection';
import WorkSection from '../components/WorkSection';
import HeroBanner from '../components/HeroBanner';
import DevelopmentSection from '../components/DevelopmentSection';
import ServicesSection from '../components/ServicesSection';
import Button from '../components/Button';

const IndexPage = () => (
  <StaticQuery
    query={graphql`
      query {
        whyUsImg: file(relativePath: { eq: "why-us.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1250, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        },
        entrackerImg: file(relativePath: { eq: "entracker-pages.png" }) {
          childImageSharp {
            fluid(maxWidth: 1250, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        },
      }
    `}
    render={data => (
      <Layout page="home">
        <SEO
          title="Your Web and Mobile Development Company"
          description="Work with us to build your next web or mobile application. We are full-stack experts fluent in Node.js, Django, React and React Native."
          keywords={[`development`, `applications`, `mobile`, 'web', `react`, 'react native', 'django', 'node', 'wordpress', 'cms']}
        />
        <HeroBanner
          title="We build digital solutions for business"
          subtitle="From initial concept to final product, we're here to help you create amazing web and mobile applications."
          hasHomeButton
        />
        <ImageSection
          image={data.whyUsImg.childImageSharp.fluid}
          id="why-us-section"
          subtitle="Why Us"
          title="Build your ideas with highly-skilled and experienced team"
          content={`We are a professional and hard working team. Our passion is to solve complex problems by implementing cutting-edge solutions.
            We guarantee an individual approach tailored to your business needs.
            Oh, and we have competitive prices.`}
        />
        <DevelopmentSection />
        <WorkSection id="our-work-section">
          <ImageSection
            image={data.entrackerImg.childImageSharp.fluid}
            rowReverse
            subtitle="Our Product"
            title="Entracker"
            content="Project management application created for engineers and detailers. Entracker has been designed based on experience of civil engineers and detailers to fulfil requirements that cannot be met by typical applications used for task and time tracking."
          >
            <Button content="Visit website" type="external-link" url="https://entracker.com" />
          </ImageSection>
        </WorkSection>
        <ServicesSection />
      </Layout>
    )}
  />
)

export default IndexPage
