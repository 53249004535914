import androidLogo from "../../images/android-logo.svg";
import djangoLogo from "../../images/django-logo.svg";
import strapiLogo from "../../images/strapi-logo.svg";
import gatsbyLogo from "../../images/gatsby-logo.svg";
import iosLogo from "../../images/ios-logo.svg";
import nodejsLogo from "../../images/nodejs-logo.svg";
import reactLogo from "../../images/react-logo.svg";
import wordpressLogo from "../../images/wordpress-logo.svg";

export const servicesList = [
  {
    title: 'Web applications',
    description: 'We leverage the very latest technology to engineer back-end and front-end solutions that simply work.',
    logos: [
      { icon: nodejsLogo, label: 'Node.js', alt: 'Node.js logo'},
      { icon: reactLogo, label: 'React', alt: 'React logo'},
      { icon: djangoLogo, alt: 'Django logo' },
    ]
  },
  {
    title: 'Mobile development',
    description: 'We build carefully crafted mobile apps quickly and efficiently, using single code base for iOS and Android.',
    logos: [
      { icon: reactLogo, label: 'React Native', alt: 'React Native logo'},
      { icon: iosLogo, label: 'iOS', alt: 'iOS logo'},
      { icon: androidLogo, label: 'Android', alt: 'Android logo'},
    ]
  },
  {
    title: 'Websites',
    description: 'Sleek, responsive website development, including static sites, dynamic CMS/headless CMS driven websites.',
    logos: [
      { icon: wordpressLogo, label: 'Wordpress', alt: 'Wordpress logo'},
      { icon: gatsbyLogo, label: 'Gatsby.js', alt: 'Gatsby logo'},
      { icon: strapiLogo, label: 'Strapi', alt: 'Strapi logo'},
    ]
  },
]
