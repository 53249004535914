import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from "gatsby-image";
import styles from "./development.module.scss";

const DevelopmentStep = ({ image, imageAlt, title, content }) => (
  <div className={styles.iconWrapper}>
    <Img className={styles.icon} fluid={image} alt={imageAlt} />
    <h3>{title}</h3>
    <p>{content}</p>
  </div>
)

const DevelopmentSection = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          discoverIcon: file(relativePath: { eq: "1-discover-icon.png" }) {
            childImageSharp {
              fluid(maxWidth: 294, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          },
          designIcon: file(relativePath: { eq: "2-design-icon.png" }) {
            childImageSharp {
              fluid(maxWidth: 294, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          },
          developmentIcon: file(relativePath: { eq: "3-development-icon.png" }) {
            childImageSharp {
              fluid(maxWidth: 294, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          },
          deployIcon: file(relativePath: { eq: "4-deploy-icon.png" }) {
            childImageSharp {
              fluid(maxWidth: 294, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          },
        }
      `}
      render={data => (
        <div className={styles.container} id="development-section">
          <div className={styles.headerWrapper}>
            <p className={styles.subtitle}>Development Process</p>
            <h2>Steps that ensure a smooth and efficient process.</h2>
          </div>
          <div className={styles.stepsContainer}>
            <DevelopmentStep
              image={data.discoverIcon.childImageSharp.fluid}
              imageAlt="Discover icon"
              title="Discover"
              content="Uncover the needs, define the goals and possible problems, understand the full idea of your project."
            />
            <DevelopmentStep
              image={data.designIcon.childImageSharp.fluid}
              imageAlt="Design icon"
              title="Design"
              content="Determine what your final digital product will look like, work on mockups and try to capture all the features."
            />
            <DevelopmentStep
              image={data.developmentIcon.childImageSharp.fluid}
              imageAlt="Develop icon"
              title="Develop"
              content="Bring the design concepts to life, write the code and perform tests to ensure everything is working as intended."
            />
            <DevelopmentStep
              image={data.deployIcon.childImageSharp.fluid}
              imageAlt="Deploy icon"
              title="Deploy"
              content="Take care of deployment, scaling and monitoring, as well as ongoing maintenance of the solution."
            />
          </div>
        </div>
      )}
    />
  )
}

export default DevelopmentSection;
