import React from 'react';
import Fade from 'react-reveal/Fade';
import { servicesList } from './constants';
import styles from "./services.module.scss";

const ServicesColumn = ({ logos, title, description }) => (
  <div className={styles.columnWrapper}>
    <div className={styles.columnInnerWrapper}>
      <h3>{title}</h3>
      <p>{description}</p>
      {logos.map(logo => (
        <div className={styles.logoWrapper} key={logo.alt}>
          <img className={logo.label ? styles.icon : styles.longIcon} src={logo.icon} alt={logo.alt} />
          {!!logo.label && <p>{logo.label}</p>}
        </div>
      ))}
    </div>
  </div>
)

const ServicesSection = () => {
  return (
    <Fade>
      <div className={styles.container} id="services-section">
        <div className={styles.headerWrapper}>
          <h2>Our Services</h2>
        </div>
        <div className={styles.servicesContainer}>
          {servicesList.map((service, i) => (
            <ServicesColumn
              key={`service-${i}`}
              logos={service.logos}
              title={service.title}
              description={service.description}
            />
          ))}
        </div>
      </div>
    </Fade>
  )
}

export default ServicesSection;
